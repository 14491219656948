import { useState } from 'react';
import { MiniKit, VerificationLevel, ISuccessResult } from '@worldcoin/minikit-js';
import { useMinikit } from './MinikitProvider';

const WorldLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const minikit = useMinikit();
  
  const handleLogin = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      console.log('Sign in with World ID button clicked');
      console.log('MiniKit available:', !!minikit);
      
      // Define verification parameters
      const verifyPayload = {
        action: "login-action",
        signal: "",
        verification_level: VerificationLevel.Orb
      };
      
      // Check if MiniKit is installed
      if (!MiniKit.isInstalled()) {
        setError('World App is not installed');
        setIsLoading(false);
        return;
      }
      
      // Trigger verification using MiniKit
      const response = await MiniKit.commandsAsync.verify(verifyPayload);
      console.log('Verification response:', response);
      
      // Check if command was successful
      if (response.finalPayload.status === 'error') {
        console.log('Error payload', response.finalPayload);
        setError('Verification failed. Please try again.');
        setIsLoading(false);
        return;
      }
      
      // Send proof to backend for verification
      const backendUrl = import.meta.env.VITE_BACKEND_URL;
      console.log('Sending verification to backend:', backendUrl);
      
      try {
        const verifyResponse = await fetch(`${backendUrl}/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            payload: response.finalPayload as ISuccessResult,
            action: verifyPayload.action,
            signal: verifyPayload.signal,
          }),
        });
        
        if (!verifyResponse.ok) {
          throw new Error(`Backend responded with status: ${verifyResponse.status}`);
        }
        
        const verification = await verifyResponse.json();
        console.log('Backend verification response:', verification);
        
        if (verification.verifyRes && verification.verifyRes.success) {
          console.log('Verification successful!');
          setIsAuthenticated(true);
        } else {
          setError('Backend verification failed');
        }
      } catch (fetchError) {
        console.error('Fetch error:', fetchError);
        setError(fetchError instanceof Error ? fetchError.message : 'Unknown error during verification');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error instanceof Error ? error.message : 'Unknown error during login');
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="flex flex-col items-center gap-3">
      <button 
        onClick={handleLogin}
        disabled={isLoading || isAuthenticated}
        className={`px-4 py-2 rounded-md ${
          isAuthenticated 
            ? 'bg-green-600 text-white' 
            : 'bg-blue-600 text-white hover:bg-blue-700'
        } ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
      >
        {isLoading ? 'Verifying...' : isAuthenticated ? 'Signed In' : 'Sign in with World ID'}
      </button>
      
      {error && (
        <div className="text-red-500 text-sm mt-2">
          {error}
        </div>
      )}
      
      {isAuthenticated && (
        <div className="text-green-500 text-sm mt-2">
          Successfully authenticated with World ID!
        </div>
      )}
    </div>
  );
};

export default WorldLoginButton;