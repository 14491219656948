import React, { useEffect, useState, createContext, useContext } from 'react';
import { MiniKit } from '@worldcoin/minikit-js';

// Create context with proper typing
const MinikitContext = createContext<MiniKit | null>(null);

// Create hook for using the context
export const useMinikit = () => useContext(MinikitContext);

// Helper function to detect World App environment
const detectWorldApp = () => {
  // Method 1: MiniKit's built-in method
  const isMinikitInstalled = (() => {
    try {
      return MiniKit.isInstalled();
    } catch (e) {
      console.error("Error in MiniKit.isInstalled():", e);
      return false;
    }
  })();

  // Method 2: User agent checking
  const userAgent = navigator.userAgent || '';
  const isWorldAppUserAgent = userAgent.includes('WorldApp') || userAgent.includes('World/');

  // Method 3: Check for World App specific globals
  const hasWorldAppGlobals = !!(
    // @ts-ignore
    (window as any).WorldApp || 
    (window as any).Android || 
    (window as any).webkit?.messageHandlers?.worldcoin
  );

  // Log all detection methods
  console.log('World App Detection Results:', { 
    usingMinikitMethod: isMinikitInstalled,
    userAgent,
    isWorldAppUserAgent,
    hasWorldAppGlobals
  });

  // Allow bypass with URL parameter for testing (remove in production)
  const urlParams = new URLSearchParams(window.location.search);
  const forceWorldApp = urlParams.get('forceWorldApp') === 'true';

  // Return true if any detection method works
  return isMinikitInstalled || isWorldAppUserAgent || hasWorldAppGlobals || forceWorldApp;
};

const MinikitProvider = ({ children }: { children: React.ReactNode }) => {
  const [isWorldApp, setIsWorldApp] = useState(false);
  const [minikit, setMinikit] = useState<MiniKit | null>(null);
  const [isDetecting, setIsDetecting] = useState(true);
  
  useEffect(() => {
    console.log("MinikitProvider mounting, starting detection...");
    
    // Add a small delay for better detection reliability
    const timer = setTimeout(() => {
      try {
        const isInstalled = detectWorldApp();
        console.log('Final detection result:', isInstalled);
        setIsWorldApp(isInstalled);
        
        if (isInstalled) {
          try {
            const kit = new MiniKit();
            console.log('MiniKit initialized successfully');
            setMinikit(kit);
          } catch (initError) {
            console.error('Error initializing MiniKit:', initError);
          }
        }
      } catch (error) {
        console.error('Error during World App detection:', error);
      } finally {
        setIsDetecting(false);
      }
    }, 1000); // Increased delay for better reliability
    
    return () => clearTimeout(timer);
  }, []);
  
  // Show loading state while detection is in progress
  if (isDetecting) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-pulse text-center">
          <p>Detecting World App...</p>
        </div>
      </div>
    );
  }
  
  return (
    <MinikitContext.Provider value={minikit}>
      {isWorldApp ? (
        children
      ) : (
        <div className="p-6 text-center bg-yellow-100 rounded-md max-w-md mx-auto my-8">
          <h2 className="text-xl font-semibold mb-3">World App Required</h2>
          <p className="mb-4">
            This app requires World App to function properly. Please make sure:
          </p>
          <ul className="text-left mb-4 space-y-2 pl-4">
            <li>• You have installed the latest version of World App</li>
            <li>• You are viewing this page within World App</li>
            <li>• You have granted all required permissions</li>
          </ul>
          <p className="mb-4 text-xs">
            Detection details (for debugging):<br />
            User Agent: {navigator.userAgent}
          </p>
          <a 
            href="https://worldcoin.org/download" 
            className="inline-block bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download World App
          </a>
          <button 
            className="mt-4 block w-full text-blue-500 underline"
            onClick={() => window.location.reload()}
          >
            I already installed it (Refresh)
          </button>
          {/* Debug bypass button - remove in production */}
          <button 
            className="mt-4 block w-full text-gray-500 text-xs"
            onClick={() => {
              window.location.href = window.location.href + 
                (window.location.search ? '&' : '?') + 
                'forceWorldApp=true';
            }}
          >
            Debug: Force World App Mode
          </button>
        </div>
      )}
    </MinikitContext.Provider>
  );
};

export default MinikitProvider;