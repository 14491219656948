import {
  MiniKit,
  tokenToDecimals,
  Tokens,
  PayCommandInput,
} from "@worldcoin/minikit-js";

const sendPayment = async () => {
  try {
    const backendUrl = import.meta.env.VITE_BACKEND_URL;
    console.log('Sending payment initiation to:', backendUrl);
    
    const res = await fetch(
      `${backendUrl}/initiate-payment`,
      {
        method: "POST",
      }
    );

    if (!res.ok) {
      throw new Error(`Payment initiation failed with status: ${res.status}`);
    }

    const { id } = await res.json();
    console.log('Payment ID:', id);

    const payload: PayCommandInput = {
      reference: id,
      to: "0x0c892815f0B058E69987920A23FBb33c834289cf", // Test address
      tokens: [
        {
          symbol: Tokens.WLD,
          token_amount: tokenToDecimals(0.5, Tokens.WLD).toString(),
        },
        {
          symbol: Tokens.USDCE,
          token_amount: tokenToDecimals(0.1, Tokens.USDCE).toString(),
        },
      ],
      description: "Watch this is a test",
    };
    
    if (MiniKit.isInstalled()) {
      return await MiniKit.commandsAsync.pay(payload);
    }
    return null;
  } catch (error: unknown) {
    console.error("Error sending payment:", error);
    return null;
  }
};

const handlePay = async () => {
  if (!MiniKit.isInstalled()) {
    console.error("MiniKit is not installed");
    return;
  }
  
  const sendPaymentResponse = await sendPayment();
  const response = sendPaymentResponse?.finalPayload;
  
  if (!response) {
    console.error("No payment response received");
    return;
  }

  if (response.status === "success") {
    try {
      const backendUrl = import.meta.env.VITE_BACKEND_URL;
      const res = await fetch(`${backendUrl}/confirm-payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ payload: response }),
      });
      
      if (!res.ok) {
        throw new Error(`Payment confirmation failed with status: ${res.status}`);
      }
      
      const payment = await res.json();
      if (payment.success) {
        console.log("SUCCESS!");
      } else {
        console.log("FAILED!");
      }
    } catch (error) {
      console.error("Payment confirmation error:", error);
    }
  } else {
    console.error("Payment response not successful:", response);
  }
};

export const PayBlock = () => {
  return (
    <button className="bg-blue-500 p-4 text-white rounded hover:bg-blue-600" onClick={handlePay}>
      Pay
    </button>
  );
};