import { PayBlock } from "./components/Pay";
import { VerifyBlock } from "./components/Verify";
import WorldLoginButton from "./components/WorldLoginButton";

export default function App() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-24 gap-y-3">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-center mb-4">WorldFund Login</h1>
        <WorldLoginButton />
      </div>
      <VerifyBlock />
      <PayBlock />
    </main>
  );
}