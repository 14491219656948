import {
  MiniKit,
  VerificationLevel,
  ISuccessResult,
} from "@worldcoin/minikit-js";
import { useCallback, useState } from "react";

export type VerifyCommandInput = {
  action: string;
  signal?: string;
  verification_level?: VerificationLevel; // Default: Orb
};

// Define more specific types to match what's coming from the API
type VerifyResponseType = {
  verifyRes?: {
    success: boolean;
    [key: string]: any;
  };
  status?: number;
  [key: string]: any;
};

const verifyPayload: VerifyCommandInput = {
  action: "test-action", // This is your action ID from the Developer Portal
  signal: "",
  verification_level: VerificationLevel.Orb, // Orb | Device
};

export const VerifyBlock = () => {
  const [handleVerifyResponse, setHandleVerifyResponse] = useState<VerifyResponseType | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleVerify = useCallback(async () => {
    try {
      setError(null);
      
      if (!MiniKit.isInstalled()) {
        console.warn("Tried to invoke 'verify', but MiniKit is not installed.");
        setError("World App is not installed");
        return null;
      }

      console.log("Starting verification process...");
      const response = await MiniKit.commandsAsync.verify(verifyPayload);
      console.log("Verification command response:", response);

      if (response.finalPayload.status === "error") {
        console.error("Command error:", response.finalPayload);
        setHandleVerifyResponse({ error: response.finalPayload });
        setError("Verification command failed");
        return null;
      }

      // Verify the proof in the backend
      const backendUrl = import.meta.env.VITE_BACKEND_URL;
      console.log('Sending verification to backend:', backendUrl);
      
      try {
        const verifyResponse = await fetch(
          `${backendUrl}/verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              payload: response.finalPayload as ISuccessResult,
              action: verifyPayload.action,
              signal: verifyPayload.signal,
            }),
          }
        );

        if (!verifyResponse.ok) {
          throw new Error(`Backend verification failed with status: ${verifyResponse.status}`);
        }

        const verifyResponseJson = await verifyResponse.json();
        console.log('Backend verification response:', verifyResponseJson);

        setHandleVerifyResponse(verifyResponseJson);
        return verifyResponseJson;
      } catch (fetchError) {
        console.error("Error fetching verify endpoint:", fetchError);
        setError(fetchError instanceof Error ? fetchError.message : 'Unknown fetch error');
        return null;
      }
    } catch (error) {
      console.error("Verification process error:", error);
      setError(error instanceof Error ? error.message : 'Unknown verification error');
      return null;
    }
  }, []);

  return (
    <div>
      <h1>Verify Block</h1>
      <button className="bg-green-500 p-4" onClick={handleVerify}>
        Test Verify
      </button>
      
      {error && (
        <div className="text-red-500 text-sm mt-2">
          {error}
        </div>
      )}
      
      {handleVerifyResponse && (
        <div>
          <pre className="text-xs p-2 bg-gray-100 rounded overflow-auto max-w-full mt-2">
            {JSON.stringify(handleVerifyResponse, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};