import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import MinikitProvider from "./components/MinikitProvider";
import { StrictMode } from "react";
import { ErudaProvider } from "./components/Eruda";
import { MiniKit } from "@worldcoin/minikit-js";

// Add debug information to console
console.log("=== App Environment Debug Info ===");
console.log("User Agent:", navigator.userAgent);
console.log("Window Size:", { 
  width: window.innerWidth, 
  height: window.innerHeight 
});

// Safe check for MiniKit availability
console.log("Is MiniKit Available:", typeof MiniKit !== 'undefined');
console.log("Is MiniKit Installed:", 
  typeof MiniKit !== 'undefined' ? 
  MiniKit.isInstalled() : 'MiniKit not available');
console.log("================================");

// Try to capture any World App specific globals
try {
  // Check for custom window properties, but use safe access patterns
  const worldAppEnvironment = !!(
    // @ts-ignore - Safely check potential World App indicators
    (window as any).WorldApp || 
    (window as any).Android || 
    (window as any).webkit?.messageHandlers
  );
  console.log("Detected possible World App environment:", worldAppEnvironment);
} catch (e) {
  console.log("Error checking World App environment:", e);
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErudaProvider>
      <MinikitProvider>
        <App />
      </MinikitProvider>
    </ErudaProvider>
  </StrictMode>
);